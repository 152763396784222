<template>
  <div>
    <div v-for="(loan, index) in currentApartment.currentLoans" :key="index + 'a'">
      <v-card class="loan-card">
        <v-card-text class="loan-card-content">
          <div class="column">
            <p><strong>Nimi: </strong>{{ getLoanByCondominiumLoanId(loan)["name"] }}</p>
            <p>
              <strong>Tyyppi:</strong>
              {{ formatMethod(getLoanByCondominiumLoanId(loan)["method"]) }}
            </p>
            <p>
              <strong>Käsittely: </strong
              >{{ formatHandling(getLoanByCondominiumLoanId(loan)["handling"]) }}
            </p>
          </div>
          <div class="column">
            <p><strong>Maksuerä:</strong> {{ formatCurrency(loan.amount) }}/kk</p>
            <p v-if="getLoanByCondominiumLoanId(loan)['method'] != 'fixed annuity'">
              <strong>Maksueriä yhteensä: </strong>
              <span :class="calcNumOfIns(loan) <= 0 ? 'error--text' : ''"
                >{{ calcNumOfIns(loan) }} kpl</span
              >
            </p>
            <p>
              <strong>Lyhennysvapaata: </strong>
              <span
                :class="
                  getLoanByCondominiumLoanId(loan)['morgageExemptionActive']
                    ? 'success--text'
                    : 'error--text'
                "
              >
                {{ getLoanByCondominiumLoanId(loan)["morgageExemptionActive"] ? "Kyllä" : "Ei" }}
              </span>
            </p>
            <p v-if="getLoanByCondominiumLoanId(loan)['morgageExemptionActive']">
              <strong>Lyhennysvapaa loppuu: </strong
              >{{ formatDate(getLoanByCondominiumLoanId(loan)["morgageExemptionEnds"]) }}
            </p>
          </div>
          <div class="column">
            <p
              class="font-weight-bold"
              :class="
                getStatusText(loan) == 'Maksettu'
                  ? 'success--text'
                  : getStatusText(loan) == 'Alkamassa'
                  ? 'error--text'
                  : 'warning--text'
              "
            >
              {{ getStatusText(loan) }}
            </p>

            <p>
              <strong>Laina {{ formatDate(loan.startDate) }}: </strong
              >{{ formatCurrency(loan.totalAmount) }}
            </p>
            <p v-if="getLoanByCondominiumLoanId(loan)['endDate']">
              <strong>Maksuohjelma päättyy:</strong>
              {{ formatDate(getLoanByCondominiumLoanId(loan)["endDate"]) }}
            </p>
            <p v-if="getStatusText(loan) != 'Alkamassa'">
              <strong>Lainaa jäljellä:</strong>
              {{
                loan.currentAmount !== undefined && loan.currentAmount !== null
                  ? formatCurrency(loan.currentAmount)
                  : formatCurrency(loan.totalAmount)
              }}
            </p>
            <p v-else><strong>Lainaa jäljellä: </strong>{{ formatCurrency(loan.totalAmount) }}</p>
          </div>
          <!-- Add other fields here -->
          <div class="actions">
            <v-icon small @click="editCurrentLoan(loan, index)">mdi-pencil</v-icon>
            <v-icon small color="error" @click="deleteCurrentLoan(loan, index)">mdi-delete</v-icon>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <!-- Add loan payment -->
    <v-row class="mt-1" dense>
      <v-col>
        <v-btn color="primary" class="mr-4" @click="newLoan('Lisää nykyinen rahoitusvastike')" small
          >Lisää nykyinen rahoitusvastike</v-btn
        >
      </v-col>
    </v-row>

    <!-- Add Old payment -->
    <v-row class="mt-1" dense>
      <v-col cols="12" md="4" sm="6">
        <v-btn color="primary" @click.stop="showAddOldLoanPaymentsDialog = true" small outlined
          >Aikaisemmat rahoitusvastikkeet
        </v-btn>
        <p class="mt-1">
          <small>
            Mikäli rahoitusvastikkeen maksuerä muuttuu, lisää loppunut vastike aikaisempiin
            rahoitusvastikkeisiin ja muuta sen jälkeen nykyisen vastikkeen maksuerää.
          </small>
        </p>
      </v-col>
    </v-row>

    <!-- Add or edit loan dialog -->
    <apartment-loan-form
      v-model="loanFormDialog"
      :title="loanFormTitle"
      :edit="editLoan"
    ></apartment-loan-form>

    <!-- Old loans dialog -->
    <add-old-loans
      v-model="showAddOldLoanPaymentsDialog"
      title="rahoitusvastikkeet"
      field="oldLoans"
    ></add-old-loans>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";
import validations from "@/validations";
import AddOldLoans from "./AddOldLoans.vue";
import globalValues from "../../configs/globalValues";
import ApartmentLoanForm from "./ApartmentLoanForm";
import moment from "moment";

export default {
  mixins: [mixins],

  components: {
    AddOldLoans,
    ApartmentLoanForm,
  },

  data() {
    return {
      loanFormDialog: false,
      loanFormTitle: "",
      editLoan: false,
      showAddOldLoanPaymentsDialog: false,
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("apartment", ["currentApartment"]),

    condominiumLoans() {
      return this.currentApartment.condominium.loans;
    },
  },

  methods: {
    ...mapMutations("apartment", ["deleteLoanPayment", "setDefaultLoanForm", "setCurrentLoan"]),

    newLoan(title) {
      this.setDefaultLoanForm();
      this.editLoan = false;
      this.loanFormTitle = title;
      this.loanFormDialog = true;
    },

    editCurrentLoan(loan, index) {
      this.setCurrentLoan({ loan, index });
      this.editLoan = true;
      this.loanFormTitle = `Muokkaa rahoitusvastiketta ${
        this.getLoanByCondominiumLoanId(loan)["name"]
      }`;
      this.loanFormDialog = true;
    },

    deleteCurrentLoan(item, index) {
      if (item.amount && item._id) {
        const charge = this.getLoanByCondominiumLoanId(item);

        if (Object.keys(charge).length > 0) {
          const answer = confirm(
            `Haluatko poistaa lainan ${charge.name}? Mikäli lainaa ei ole lisätty aikaisempiin rahoitusvastikkeisiin, tee se ensin. Mikäli lisäksi haluat poistaa kyseisen vastikkeen kaikkien kiinteistöön kuuluvien vuokrakohteiden takaa, poista vastike kiinteistön/taloyhtiön kautta.`
          );
          if (answer) this.deleteLoanPayment(index);
        } else {
          this.deleteLoanPayment(index);
        }
      } else {
        this.deleteLoanPayment(index);
      }
    },

    getLoanByCondominiumLoanId(item) {
      const idx = this.condominiumLoans.findIndex(
        (el) => String(el._id) == String(item.condominiumLoanId)
      );

      if (idx != -1) {
        return this.condominiumLoans[idx];
      } else {
        return {};
      }
    },

    calcNumOfIns(loan) {
      return Math.round(
        moment(this.getLoanByCondominiumLoanId(loan)["endDate"]).diff(
          loan.startDate,
          "months",
          true
        )
      );
    },

    getStatusText(loan) {
      const loanDetails = this.getLoanByCondominiumLoanId(loan);

      if (Object.keys(loanDetails).length > 0) {
        if (loan.startDate) {
          const thisMonthEnd = moment().endOf("month").format("YYYY-MM-DD");
          const startDate = moment(loan.startDate).format("YYYY-MM-DD");

          if (moment(startDate).isSameOrAfter(thisMonthEnd)) {
            return "Alkamassa";
          } else if (loan.currentAmount == 0) {
            return "Maksettu";
          } else {
            if (
              loan.currentAmount > 0 ||
              loan.currentAmount === null ||
              loan.currentAmount === undefined
            ) {
              return "Maksussa";
            }
          }
        }
      }
    },

    formatHandling(text) {
      if (text === "funding") return "Rahastoitu";
      if (text == "income recognition") return "Tuloutettu";
      else return "";
    },

    formatMethod(method) {
      if (method == "annuity") return "Annuiteetti";
      if (method == "equal instalment") return "Tasalyhenteinen";
      if (method == "fixed annuity") return "Kiinteä tasaerä";
    },
  },
};
</script>

<style scoped>
.loan-card {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.loan-card-content {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin: 10px;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  margin: 10px;
}

@media (max-width: 960px) {
  .column {
    flex-basis: calc(33.33% - 20px);
  }

  .actions {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .column {
    flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 600px) {
  .column {
    flex-basis: calc(100% - 20px);
    margin: 5px;
  }

  .actions {
    margin: 5px;
  }
}
</style>
